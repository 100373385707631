import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  Alert,
  CardSubtitle,
  FormFeedback
} from "reactstrap"
import Slider from "react-slick";
import { useSelector, useDispatch, connect } from "react-redux";
import { withTranslation } from "react-i18next"
import * as Yup from "yup";
import { useFormik } from "formik";
import { withRouter, Link } from "react-router-dom"

import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { setCustomer, getProduct, unsetProduct, amountUP, amountDown, setProduct, removeFromCart, saveOrder, getOffers } from "../../../store/actions";
import ProductModal from "./ProductModal";

const OrdersCheckout = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [productList, setproductList] = useState([]);
  const [activeTab, setactiveTab] = useState("1")
  const [user, setUser] = useState({})
  const [warningProduct, setWarningProduct] = useState("");
  const [warningCart, setWarningCart] = useState("");
  const [detailPresent, setDetailPresent] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [toShow, setToShow] = useState(4);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setUser(JSON.parse(localStorage.getItem("authUser")))
    }
  }, [props.success])

  function actionHandler() {
    setWarningCart("")
    if(activeTab === "1") {
      setactiveTab("2")
    } else if (activeTab === "2") {
      if(productList && productList.length && productList.length > 0) {
        setactiveTab("3")
      } else {
        setWarningCart("Carrito vacío. Para poder continuar, debes agregar un producto a tu carrito de pedido.")
      }
    } else {
      if(customerValidation.isValid && productList && productList.length && productList.length > 0) {
        let request = {
          customer,
          products: productList,
          subtotal
        }
        dispatch(saveOrder(request, props.history));
      }
    }
  }
  const productValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Por favor ingresa el código de fabricante del producto que desea agregar al carrito.")
    }),
    onSubmit: (values) => {
      setWarningCart("")
      setWarningProduct("")
      dispatch(getProduct({product: {...values}}));
    }
  });

  const customerValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: user?.code,
      nit: user?.billing?.nit ?? '',
      name: user?.name ?? '',
      email: user?.email ?? '',
      phone: user && user?.phones && user?.phones?.length && user?.phones.length > 0 && user?.phones[0]?.phone.length == 8 ? user?.phones[0]?.phone : '',
      contact: user?.contact ?? ''
    },
    validationSchema: Yup.object({
      nit: Yup.string().required("Por favor ingresa NIT."),
      name: Yup.string().required("Por favor ingresa nombre."),
      email: Yup.string().email('Por favor ingresa un correo electrónico valido.').required("Por favor ingresa correo electrónico."),
      phone: Yup.string().min(8, 'Por favor ingresa un número de teléfono valido.').max(8, 'Por favor ingresa un número de teléfono valido.').required("Por favor ingresa teléfono."),
      contact: Yup.string().required("Por favor ingresa nombre de contacto.")
    }),
    onSubmit: (values) => {
      setWarningCart("")
      dispatch(setCustomer(values));
      actionHandler();
    }
  });

  useEffect(() => {
    customerValidation.validateForm();
    dispatch(getOffers({type: 1}));
  }, []);

  useEffect(() => {
    customerValidation.validateForm();
  }, [activeTab]);

  const { offersResponse } = useSelector(state => ({
    offersResponse: state?.Home?.offers,
  }));
  
  useEffect(() => {
    if(offersResponse && offersResponse.length && offersResponse.length > 0) {
      setOffers(offersResponse)
      if(offersResponse.length < 4 ){
        setToShow(offersResponse.length)
      }
    }
  }, [offersResponse]);

  const { cartItems } = useSelector(state => ({
    cartItems: state?.Orders?.cart
  }));

  const { productResponse, productError, loadingProduct, loading, error } = useSelector(state => ({
    productResponse: state?.Orders?.product,
    productError: state?.Orders?.productError,
    loadingProduct: state?.Orders?.loadingProduct,
    loading: state?.Orders?.loading,
    error: state?.Orders?.error
  }));

  const { customer } = useSelector(state => ({
    customer: state?.Orders?.customer
  }));

  useEffect(() => {
    productValidation.resetForm();
    if(productResponse && !productError) {
      if (cartItems && cartItems.length && cartItems.length > 0) {
        let productCart = cartItems.find(cartItem => cartItem.barcode == productResponse.barcode)
        if(productCart && !detailPresent){
          setWarningProduct("El producto indicado ya se encuentra en el carrito, puede aumentar/disminuir la cantidad solicitada en el panel de la derecha.")
          dispatch(unsetProduct());
        } else {
          setWarningProduct("")
          toggleViewModal()
        }
      } else {
        setWarningProduct("")
        toggleViewModal()
      }
    } else if(productError) {
      dispatch(unsetProduct());
    }
  }, [productResponse, productError]);

  useEffect(() => {
    setSubtotal(cartItems.reduce((total, p) => total + p.amount * p.price, 0))
    setproductList(cartItems)
  }, [cartItems, cartItems.length]);

  const toggleViewModal = () => {
    setModal(!modal)
    if(modal) {
      setDetailPresent(false)
    }
  };
  
  const showDetail = (product) => {
    setDetailPresent(true)
    dispatch(setProduct(product));
  };

  function countUP(barcode, prev_amount) {
    dispatch(amountUP(barcode, prev_amount));
  }

  function countDown(barcode, prev_amount) {
    dispatch(amountDown(barcode, prev_amount));
  }

  function removeCartItem(barcode) {
    dispatch(removeFromCart(barcode));
  }

  const bannerSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    infinite: true,
    pauseOnHover: true,
    initialSlide: 1,
    slidesToShow: toShow,
    slidesToScroll: 1,
  };

  return (
    <React.Fragment>
      <ProductModal isOpen={modal} toggle={toggleViewModal} />
      <div className="page-content">
        <MetaTags>
          <title>Nuevo Pedido | GRUPO MASTER</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Pedidos" breadcrumbItem="Nuevo pedido" />
          {error ? <Alert color="danger">{error}</Alert> : null}
          <Row>
            <Slider {...bannerSettings}>
            {offers.map((offer, key) => (
              <Col key={key}>
                <Card className="mini-stats-wid me-3">
                  <img
                      className="rounded img-fluid mx-auto d-block"
                      src={offer?.image?.url}
                      alt=""
                    />
                </Card>
              </Col>
            ))}
            </Slider>
          </Row>
          <div className="checkout-tabs">
            <Row>
              <Col xl="2" sm="3">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Mis datos</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        if(activeTab == 1){
                          customerValidation.handleSubmit()
                        } else {
                          setactiveTab("2")
                        }
                      }}
                    >
                      <i className="bx bx-cart d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Ingreso de productos</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        if(activeTab == 1) {
                          if(!customerValidation.isValid){
                            customerValidation.handleSubmit()
                          } else if(productList && productList.length && productList.length > 0) {
                            setactiveTab("3")
                          } else {
                            customerValidation.handleSubmit()
                            actionHandler()
                          }
                        } else if(activeTab == 2) {
                          actionHandler()
                        } else {
                          setactiveTab("3")
                        }
                      }}
                    >
                      <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Confirmación de pedido</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xl="10" sm="9">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div>
                          <CardTitle>Datos del cliente</CardTitle>
                          <CardSubtitle className="mb-3">
                            Revise toda la información a continuación
                          </CardSubtitle>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              customerValidation.handleSubmit();
                              return false;
                            }}
                          >
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="nit"
                                md="2"
                                className="col-form-label"
                              >
                                NIT
                              </Label>
                              <Col md="10">
                                <Input
                                  name="nit"
                                  type="text"
                                  className="form-control"
                                  id="nit"
                                  disabled = {customerValidation.initialValues.nit}
                                  placeholder="Ingresar NIT"
                                  onChange={customerValidation.handleChange}
                                  onBlur={customerValidation.handleBlur}
                                  value={customerValidation.values.nit || ""}
                                  invalid={
                                    customerValidation.touched.code && customerValidation.errors.nit ? true : false
                                  }
                                />
                                {customerValidation.touched.nit && customerValidation.errors.nit ? (
                                  <FormFeedback type="invalid">{customerValidation.errors.nit}</FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="name"
                                md="2"
                                className="col-form-label"
                              >
                                Nombre
                              </Label>
                              <Col md="10">
                                <Input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  disabled = {customerValidation.initialValues.name}
                                  placeholder="Ingresar nombre"
                                  onChange={customerValidation.handleChange}
                                  onBlur={customerValidation.handleBlur}
                                  value={customerValidation.values.name || ""}
                                  invalid={
                                    customerValidation.touched.name && customerValidation.errors.name ? true : false
                                  }
                                />
                                {customerValidation.touched.name && customerValidation.errors.name ? (
                                  <FormFeedback type="invalid">{customerValidation.errors.name}</FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="email"
                                md="2"
                                className="col-form-label"
                              >
                                Correo electrónico
                              </Label>
                              <Col md="10">
                                <Input
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  disabled = {customerValidation.initialValues.email}
                                  placeholder="Ingresar correo electrónico"
                                  onChange={customerValidation.handleChange}
                                  onBlur={customerValidation.handleBlur}
                                  value={customerValidation.values.email || ""}
                                  invalid={
                                    customerValidation.touched.email && customerValidation.errors.email ? true : false
                                  }
                                />
                                {customerValidation.touched.email && customerValidation.errors.email ? (
                                  <FormFeedback type="invalid">{customerValidation.errors.email}</FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="phone"
                                md="2"
                                className="col-form-label"
                              >
                                Teléfono
                              </Label>
                              <Col md={10}>
                                <Input
                                name="phone"
                                  type="text"
                                  className="form-control"
                                  id="phone"
                                  disabled = {customerValidation.initialValues.phone}
                                  placeholder="Ingresar número de teléfono"
                                  onChange={customerValidation.handleChange}
                                  onBlur={customerValidation.handleBlur}
                                  value={customerValidation.values.phone || ""}
                                  invalid={
                                    customerValidation.touched.phone && customerValidation.errors.phone ? true : false
                                  }
                                />
                                {customerValidation.touched.phone && customerValidation.errors.phone ? (
                                  <FormFeedback type="invalid">{customerValidation.errors.phone}</FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="contact"
                                md="2"
                                className="col-form-label"
                              >
                                Contacto
                              </Label>
                              <Col md="10">
                                <Input
                                  name="contact"
                                  type="text"
                                  className="form-control"
                                  id="contact"
                                  disabled = {customerValidation.initialValues.contact}
                                  placeholder="Ingresar nombre de contacto"
                                  onChange={customerValidation.handleChange}
                                  onBlur={customerValidation.handleBlur}
                                  value={customerValidation.values.contact || ""}
                                  invalid={
                                    customerValidation.touched.contact && customerValidation.errors.contact ? true : false
                                  }
                                />
                                {customerValidation.touched.contact && customerValidation.errors.contact ? (
                                  <FormFeedback type="invalid">{customerValidation.errors.contact}</FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <Row className="mt-4">
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="submit"
                                    color="success"
                                    className="btn"
                                  >
                                    Continuar
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="2"
                        id="v-pills-payment"
                        role="tabpanel"
                        aria-labelledby="v-pills-payment-tab"
                      >
                        { warningCart ? <Alert color="warning">{warningCart}</Alert> : null }
                        <Row>
                        <Col lx="5" className="border-end">
                          <div className="me-3">
                              <CardTitle>Carrito de pedido</CardTitle>
                              <CardSubtitle className="mb-4">
                                Complete toda la información a continuación
                              </CardSubtitle>
                              <h5 className="mt-5 mb-1 font-size-15">
                                Agregar producto
                              </h5>
                              <div className="p-4 border">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    productValidation.handleSubmit();
                                    return false;
                                  }}
                                >
                                  <FormGroup className="mb-0">
                                    <Label htmlFor="cardnumberInput">
                                      Código de fabricante
                                    </Label>
                                    <Input
                                      name="code"
                                      type="text"
                                      className="form-control"
                                      // id="cardnumberInput"
                                      placeholder="Ingrese el código de fabricante del producto a agregar"
                                      onChange={productValidation.handleChange}
                                      onBlur={productValidation.handleBlur}
                                      value={productValidation.values.code || ""}
                                      invalid={
                                        productValidation.touched.code && productValidation.errors.code ? true : false
                                      }
                                    />
                                    {productValidation.touched.code && productValidation.errors.code ? (
                                      <FormFeedback type="invalid">{productValidation.errors.code}</FormFeedback>
                                    ) : null}
                                    {productError ? (
                                      <Row className="mt-4">
                                        <Alert color="danger">{productError}</Alert>
                                      </Row>
                                    ) : null}
                                    {warningProduct ? (
                                      <Row className="mt-4">
                                        <Alert color="warning">{warningProduct}</Alert>
                                      </Row>
                                    ) : null}
                                  </FormGroup>
                                  <Row className="mt-4 mb-2 justify-content-center">
                                    <Col xl={5}>
                                      {!loadingProduct ? 
                                        <Button
                                          type="submit"
                                          color="info"
                                          className="btn"
                                        >
                                          <i className="bx bx-search-alt me-2" />Buscar producto
                                        </Button>
                                      :
                                        <Button
                                          type="submit"
                                          color="info"
                                          className="btn"
                                        >
                                          <i className="bx bx-loader bx-spin me-2" />Buscar producto
                                        </Button>
                                      }
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </div>
                        </Col>
                        <Col xl="7">
                          <div className="table-responsive">
                            <Table className="table align-middle mb-0 table-nowrap">
                              <thead className="table-light">
                                <tr>
                                  <th>Producto</th>
                                  <th>Descripción</th>
                                  <th>Precio</th>
                                  <th>Cantidad</th>
                                  <th colSpan="2">Subtotal</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productList.map(product => (
                                  <tr key={product.barcode}>
                                    <td>
                                      {/* <img
                                        src={product.img}
                                        alt="product-img"
                                        title="product-img"
                                        className="avatar-md"
                                      /> */}
                                      <h5 className="font-size-14 text-truncate">
                                        <Link
                                          to="#"
                                          onClick={() => showDetail(product)}
                                          className="text-dark"
                                        >
                                          {product.barcode}
                                        </Link>
                                      </h5>
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        {product.description}
                                      </p>
                                    </td>
                                    <td>GTQ {product.price}</td>
                                    <td>
                                      <div style={{ width: "120px" }}>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                countUP(product.barcode, product.amount);
                                              }}>+
                                            </button>
                                          </div>
                                          <Input
                                            className="text-center"
                                            type="text"
                                            value={product.amount}
                                            name="demo_vertical"
                                            readOnly
                                          />
                                          <div className="input-group-append">
                                            <button type="button" className="btn btn-primary"
                                              onClick={() => {
                                                countDown(product.barcode, product.amount);
                                              }}>-</button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>GTQ {(product.amount * product.price)}</td>
                                    <td>
                                      <Link
                                        to="#"
                                        onClick={() => removeCartItem(product.barcode)}
                                        className="action-icon text-danger"
                                      >
                                        {" "}
                                        <i className="mdi mdi-trash-can font-size-18" />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          {!productList || !productList.length || productList.length == 0 ? 
                            <blockquote className="blockquote  blockquote-reverse font-size-16 mt-5">
                              <p>
                                No tienes productos en tu carrito.
                              </p>
                              <footer className="blockquote-footer">
                                Busca por código de fabricante el producto que deseas agregar para completar tu pedido.
                              </footer>
                            </blockquote>
                          :
                            null
                          }
                        </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm="12">
                            <div className="text-sm-end">
                              <Button
                                color="success"
                                className="btn"
                                onClick={() => {
                                  actionHandler()
                                }}
                              >
                                Continuar
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <CardTitle className="mb-4">
                              Resumen del pedido
                            </CardTitle>
                            <div className="table-responsive">
                              <Table className="table align-middle mb-0 table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Precio</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productList.map((product, key) => (
                                    <tr key={"_orderSummary_" + key}>
                                      <th scope="row">
                                        {/* <img
                                          src={orderitem.img}
                                          alt="product-img"
                                          title="product-img"
                                          className="avatar-md"
                                        /> */}
                                        <h5 className="font-size-14 text-truncate">
                                          <Link
                                            to="/ecommerce-product-detail"
                                            className="text-dark"
                                          >
                                            {product.barcode}{" "}
                                          </Link>
                                        </h5>
                                      </th>
                                      <td>
                                        <h5 className="text-truncate font-size-11">
                                          {product.description}
                                        </h5>
                                        <p className="text-muted mb-0">
                                          GTQ {product.price} x {product.amount}
                                        </p>
                                      </td>
                                      <td>
                                        GTQ {product.price * product.amount}
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td colSpan="2">
                                      <h6 className="m-0 text-end">
                                        Subtotal:
                                      </h6>
                                    </td>
                                    <td>GTQ {subtotal}</td>
                                  </tr>
                                  {/* <tr>
                                    <td colSpan="3">
                                      <div className="bg-primary bg-soft p-3 rounded">
                                        <h5 className="font-size-14 text-primary mb-0">
                                          <i className="fas fa-shipping-fast me-2" />{" "}
                                          Shipping{" "}
                                          <span className="float-end">
                                            Free
                                          </span>
                                        </h5>
                                      </div>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td colSpan="2">
                                      <h6 className="m-0 text-end">Total:</h6>
                                    </td>
                                    <td>GTQ {subtotal}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <Row className="mt-4">
                              <Col sm="12">
                                <div className="text-sm-end">
                                {!loading ? 
                                    <Button
                                      color="success"
                                      className="btn"
                                      onClick={() => {
                                        actionHandler()
                                      }}
                                    >
                                      Finalizar pedido
                                    </Button>
                                  :
                                  <Button
                                      color="success"
                                      className="btn"
                                      disabled = {true}
                                    >
                                      <i className="bx bx-loader bx-spin me-2" />Finalizar pedido
                                    </Button>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

OrdersCheckout.propTypes = {
  history: PropTypes.object,
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(OrdersCheckout))
)