import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  Input,
  Row,
  Col,
  Alert,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux";
import { unsetProduct, addToCart, amountUP, amountDown } from "../../../store/actions";

const ProductModal = props => {
  const { isOpen, toggle } = props
  
  const dispatch = useDispatch();
  const [product, setProduct] = useState({});
  const [error, setError] = useState("");

  const toggleViewModal = () => {
    setProduct({})
    setError("")
    dispatch(unsetProduct());
    toggle()
  };

  const addToCartHandler = () => {
    if(product.amount > 0){
      dispatch(addToCart(product));
      toggleViewModal()
    } else {
      setError('Debe agregar al menos una unidad.')
    }
  };
  
  const { productResponse, productError } = useSelector(state => ({
    productResponse: state?.Orders?.product,
    productError: state?.Orders?.productError
  }));

  useEffect(() => {
    if(productResponse && !productError) {
      setProduct(productResponse)
    }
  }, [productResponse, productError]);
  

  function countUP(barcode, prev_amount) {
    if (product.amount < product.stock) {
      setError("")
      let amount = product.amount + 1;
      setProduct({
        ...product,
        amount
      })
      if(!product.toAdd){
        dispatch(amountUP(barcode, prev_amount));
      }
    }
  }

  function countDown(barcode, prev_amount) {
    if ((product.toAdd && product.amount > 0) || (!product.toAdd && product.amount > 1)) {
      setError("")
      let amount = product.amount - 1;
      setProduct({
        ...product,
        amount
      })
      if(!product.toAdd) {
        dispatch(amountDown(barcode, prev_amount));
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggleViewModal}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggleViewModal}>Detalle de producto</ModalHeader>
        <ModalBody>
          {error ? <Alert color="danger">{error}</Alert> : null}
          {product && product.code? 
            <>
              <p className="mb-2">
                Código de fabricante: <span className="text-primary">#{product.code}</span>
              </p>
              <div className="mt-4">
                <h5 className="mb-2">Especificaciones:</h5>
                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>
                      {product ? 
                        <>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "150px" }}
                              className={"text-capitalize"}
                            >
                              Código SAP
                            </th>
                            <td>{product.barcode}</td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "150px" }}
                              className={"text-capitalize"}
                            >
                              Descripción
                            </th>
                            <td>{product.description}</td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "150px" }}
                              className={"text-capitalize"}
                            >
                              Precio
                            </th>
                            <td>{product.price}</td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "150px" }}
                              className={"text-capitalize"}
                            >
                              Existencia
                            </th>
                            <td>{product.stock}</td>
                          </tr>
                        </>
                        : null}
                    </tbody>
                  </Table>
                </div>
                <Row className="mt-4 mb-2 justify-content-center">
                  <Col xl={4}>
                    <div style={{ width: '100%' }}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              countUP(product.barcode, product.amount);
                            }}>+
                          </button>
                        </div>
                        <Input
                          className="text-center"
                          type="text"
                          value={product.amount}
                          name="demo_vertical"
                          readOnly
                        />
                        <div className="input-group-append">
                          <button type="button" className="btn btn-primary"
                            onClick={() => {
                              countDown(product.barcode, product.amount);
                            }}>-</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </> 
          : 
            <p className="mb-2 text-center">
              Algo salió mal, intentalo más tarde.
            </p>
          }
        </ModalBody>
        <ModalFooter>
          {product && product.toAdd ? 
            <Button
              type="button"
              color="primary"
              className="btn  mt-2 me-1"
              onClick={addToCartHandler}
            >
              <i className="bx bx-cart me-2" /> Agregar a carrito
            </Button>
          :
            <Button 
              type="button" 
              color="secondary" 
              onClick={toggleViewModal}>
              Cerrar
            </Button>
          }
        </ModalFooter>
      </div>
    </Modal>
  )
}

ProductModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ProductModal
