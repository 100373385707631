import { call, put, takeEvery } from "redux-saga/effects"
import { SAVE_ORDER, GET_PRODUCT, ORDER_PRODUCT_API_ERROR, GET_ORDERS, GET_ORDER_DETAIL } from "./actionTypes"
import { apiError, setOrderResult, setProduct, apiProductError, setOrdersResult, setOrderDetailResult, historyError } from "./actions"
import { Save, Product, Orders, Detail } from "../../helpers/backend_helper"

function* getProduct({ payload: { request } }) {
  try {
    const response = yield call(Product, request)
    if (response.code && response.code == 1 && response.product){
      const product = {
        ...response.product,
        amount: 1,
        toAdd: true
      }
      yield put(setProduct(product))
    } else if (response.message){
      yield put(apiProductError(response.message))
    } else{
      yield put(apiProductError('Ocurrió un error buscando el producto solicitado.'))
    }
  } catch (error) {
    yield put(apiProductError('Ocurrió un error buscando el producto solicitado.'))
  }
}

function* saveOrder({ payload: { request, history }}) {
  try {
    const response = yield call(Save, request)
    if (response.code && response.code == 1 && response.order){
      yield put(setOrderResult(response.order))
      history.push("/orders/history")
    } else if (response.message){
      yield put(apiError(response.message))
    } else{
      yield put(apiError('Ocurrió un error guardando tu pedido. Intenta de nuevo.'))
    }
  } catch (error) {
    yield put(apiError('Ocurrió un error guardando tu pedido. Intenta de nuevo.'))
  }
}

function* getOrders({ payload: { request } }) {
  try {
    const response = yield call(Orders, request)
    if (response.code && response.code == 1 && response.orders && response.orders.length && response.orders.length > 0){
      yield put(setOrdersResult(response.orders))
    } else if (response.message){
      yield put(historyError(response.message))
    } else{
      yield put(historyError('Ocurrió un error obteniendo historial de pedidos.'))
    }
  } catch (error) {
    yield put(historyError('Ocurrió un error obteniendo historial de pedidos.'))
  }
}

function* getOrderDetail({ payload: { request } }) {
  try {
    const response = yield call(Detail, request)
    if (response.code && response.code == 1 && response.products && response.products.length && response.products.length > 0){
      const detail = {
        order : request.code,
        products: response.products
      }
      yield put(setOrderDetailResult(detail))
    } else if (response.message){
      yield put(historyError(response.message))
    } else{
      yield put(historyError('Ocurrió un error obteniendo detalle del pedido.'))
    }
  } catch (error) {
    yield put(historyError('Ocurrió un error obteniendo detalle del pedido.'))
  }
}

function* orderSaga() {
  yield takeEvery(GET_PRODUCT, getProduct)
  yield takeEvery(SAVE_ORDER, saveOrder)
  yield takeEvery(GET_ORDERS, getOrders)
  yield takeEvery(GET_ORDER_DETAIL, getOrderDetail)
}

export default orderSaga
