export const GET_PRODUCT = "GET_PRODUCT"
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL"
export const SAVE_ORDER = "SAVE_ORDER"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const ADD_PRODUCT_CART = "ADD_PRODUCT_CART"
export const REMOVE_PRODUCT_CART = "REMOVE_PRODUCT_CART"
export const AMOUNT_UP_PRODUCT_CART = "AMOUNT_UP_PRODUCT_CART"
export const AMOUNT_DOWN_PRODUCT_CART = "AMOUNT_DOWN_PRODUCT_CART"
export const ORDER_API_ERROR = "ORDER_API_ERROR"
export const SET_ORDER = "SET_ORDER"
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL"
export const SET_ORDERS = "SET_ORDERS"
export const SET_PRODUCT = "SET_PRODUCT"
export const UNSET_PRODUCT = "UNSET_PRODUCT"
export const UNSET_ORDER_DETAIL = "UNSET_ORDER_DETAIL"
export const ORDER_PRODUCT_API_ERROR = "ORDER_PRODUCT_API_ERROR"
export const ORDER_HISTORY_ERROR = "ORDER_HISTORY_ERROR"